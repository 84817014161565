import { javascript } from '@api/index'
import { removeUndefined } from '@avvoka/shared'
import { StoreMode, useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

type DocxSettings = CamelCasedProps<
  Backend.Models.TemplateVersion['docx_settings']
>

export type CustomClauseVariantStoreType = ReturnType<
  typeof useCustomClauseVariantStore
>
export const allCustomClauseVariantStores = ref<Array<CustomClauseVariantStoreType>>([])

export const createCustomClauseVariantStore = (uniqueId: string | number) => {
  const customClauseVariantStore = defineStore(
    'custom-clause-variant-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<Backend.Models.CustomClauseVariant>(
        javascript.customClauseVariant
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof Backend.Models.CustomClauseVariant> = []
      ) => {
        return hydration.hydrate({ id }, fields)
      }
      allCustomClauseVariantStores.value.push(
        customClauseVariantStore as unknown as CustomClauseVariantStoreType
      )
      const styles = hydration.hydratedComputed('docx_settings', (settings) => {
        return removeUndefined({
          formats: settings.formats,
          docxNamesByOrigin: settings.docx_names_by_origin,
          stylesRelations: settings.stylesRelations,
          inactiveFormats: settings.inactiveFormats,
          metadata: settings.metadata,
          version: settings.version,
          dataDocxRef: settings['data-docx-ref']
        } satisfies DocxSettings) as DocxSettings
      })

      const defaultStyle = computed<
        Backend.Models.TemplateVersion.StyleFormat[string] & { key: string }
      >(() => {
        const entries = Object.entries(styles.value?.formats ?? {})
        const result =
          entries.find(([, value]) => value['default'] === true) ??
          entries.find(([key]) => key === 'Normal')
        return {
          key: result?.[0] ?? '',
          ...(result?.[1] ?? {})
        } as const
      })

      watch(
        hydration.hydratedData,
        (data) => {
          const docxSettings = data?.docx_settings
          if (docxSettings == null) return

          if (docxSettings.formats == null) {
            docxSettings.formats = {}
          }
          if (docxSettings.inactiveFormats == null) {
            docxSettings.inactiveFormats = {}
          }
        },
        { immediate: true }
      )

      const $default = () => {
        hydration.hydratedData.value = {
          id: -1,
          docx_settings: {
            docx_names_by_origin: {},
            stylesRelations: {},
            inactiveFormats: {},
            formats: {
              Normal: {
                definition: {
                  fontSize: { size: '11' },
                  font: { font: 'arial' }
                },
                name: 'Normal',
                default: true
              }
            },
            version: 1,
            metadata: {}
          }
        } as unknown as Backend.Models.CustomClauseVariant
        hydration.hydrated.value = true
        hydration.storeMode.value = StoreMode.NewData
      }

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        /** @deprecated use docxSettings */
        styles,
        stylesOrSetDefault: computed<DocxSettings>(() => {
          let result: DocxSettings = {
            formats: {},
            docxNamesByOrigin: {},
            stylesRelations: {},
            inactiveFormats: {},
            metadata: {},
            version: 1,
            dataDocxRef: ''
          }

          try {
            result = {
              ...result,
              ...styles.value
            }
          } catch (_) {}

          hydration.hydratedData.value!.docx_settings = {
            formats: result.formats,
            docx_names_by_origin: result.docxNamesByOrigin ?? {},
            stylesRelations: result.stylesRelations ?? {},
            inactiveFormats: result.inactiveFormats ?? {},
            metadata: result.metadata,
            version: result.version,
            'data-docx-ref': result.dataDocxRef
          }

          return result
        }),
        docxSettings: styles,
        defaultStyle: defaultStyle,
        setDefaultStyle(key: string) {
          delete hydration.hydratedData.value!.docx_settings.formats[
            defaultStyle.value.key
          ].default
          hydration.hydratedData.value!.docx_settings.formats[key].default =
            true
        },
        $default,
        setDocxSettings(
          settings: Backend.Models.TemplateVersion['docx_settings']
        ) {
          if (hydration.hydratedData) {
            hydration.hydratedData.value!.docx_settings = settings
          }
        }
      }
    }
  )
  return customClauseVariantStore
}

export const useCustomClauseVariantStore = createCustomClauseVariantStore('current')
